import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Seo from "../components/Seo"
import Breadcrumbs from "../components/Breadcrumbs"
import HeroImage from "../components/Image/HeroImage"
import RichText from "../components/RichText"
import BlockList from "../components/PageLayout/BlockList"
import { useLocale } from "../context/LocaleContext"

import { getLocaleConfig, getLangLocale } from "../utils/utils_locales"

const NotFoundPage = props => {
  const query = useStaticQuery(graphql`
    query {
      heroImage: allContentstackConfig(filter: { code_id: { eq: "hero404" } }) {
        nodes {
          id
          image {
            ...HeroImageFields
          }
          publish_details {
            locale
          }
        }
      }

      content: allContentstackConfig(
        filter: { code_id: { eq: "content404" } }
      ) {
        nodes {
          ...ConfigFields
        }
      }
    }
  `)

  const { setLocaleData } = useLocale()

  const langPart = props.location.pathname.split("/").filter(Boolean)[0];
  const { locale, lang } = getLangLocale(langPart);

  const pageContext = {
    locale: locale,
    localeDir: lang,
    language: lang,
  }

  setLocaleData({
    locale: locale,
    localeDir: lang,
  })

  // pull out the config data for the current locale (default: en-au)
  const heroData = getLocaleConfig(query.heroImage, pageContext.locale)
  const heroImage = heroData?.image ? heroData.image[0] : undefined
  const contentData = getLocaleConfig(query.content, pageContext.locale)
  
  return (
    <>
      {/* Breadcrumbs */}
      <Breadcrumbs currentPage="Page not found" pageContext={pageContext} />

      {/* Hero Image */}
      {heroImage && (
        <section className="hero">
          <div className="grid-container">
            <HeroImage image={heroImage} />
          </div>
        </section>
      )}

      <section className="hero">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <div className="page__body">
                <RichText
                  content={contentData?.rich_text}
                  cssClass="content"
                  pageContext={pageContext}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
    </>
  )
}

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
